import { useEffect, useState } from 'react';
// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';
// utils
import { qvendeApi } from '../utils/api';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  const [company, setCompany] = useState(null);

  useEffect(() => {
    async function getCompany() {
      if (user?.companyAdmin?.company?.id) {
        const { data } = await qvendeApi.get(`company/${user?.companyAdmin?.company?.id}`);
        setCompany(data);
      } else {
        const { data } = await qvendeApi.get(`company/${user?.companyAnalist?.company?.id}`);
        setCompany(data);
      }
    }

    getCompany();
  }, [user]);

  return (
    <MAvatar
      src={company?.logoUrl ?? null}
      alt={user?.companyAdmin?.name}
      color={company?.logoUrl ? 'default' : createAvatar(user?.companyAdmin?.name).color}
      {...other}
    >
      {createAvatar(user?.companyAdmin?.name).name}
    </MAvatar>
  );
}
