import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { Box, Typography, Stack } from '@material-ui/core';
import { PATH_DASHBOARD } from '../routes/paths';

NavbarSection.propTypes = {
  isShow: PropTypes.bool,
  navConfig: PropTypes.array
};

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, active }) {
  // eslint-disable-next-line no-nested-ternary
  const getColor = (ishover = false) => (active(item.path) ? 'grey.600' : !ishover ? 'primary.main' : 'primary.light');

  return (
    <Box
      component={RouterLink}
      to={item.path}
      sx={{
        textDecoration: 'none'
      }}
    >
      <Typography
        variant="button"
        color={getColor()}
        sx={{
          textTransform: 'none',
          fontSize: '15px',
          '&:hover': {
            color: getColor(true)
          }
        }}
      >
        {item.title}
      </Typography>
    </Box>
  );
}

export default function NavbarSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const match = (path) => (path ? !!matchPath({ path }, pathname) : false);

  return (
    <Stack direction="row" spacing={4} {...other}>
      {navConfig.map((item) => (
        <NavItem
          key={item.title}
          item={item}
          active={match}
          isWhiteTheme={
            !(
              matchPath({ path: PATH_DASHBOARD.root }, pathname) ||
              matchPath({ path: PATH_DASHBOARD.selectiveProcesses.root }, pathname) ||
              pathname.includes('viewSelectiveProcess')
            )
          }
        />
      ))}
    </Stack>
  );
}
