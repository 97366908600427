import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Button, AppBar, Toolbar, Container, Typography } from '@material-ui/core';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/logos/Logo';
import { MHidden } from '../../components/@material-extend';
//
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  const handleScrollDown = () => {
    window.scrollBy(0, 3600);
  };
  const handleScrollUp = () => {
    window.scrollBy(0, 5000);
  };

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            <Logo color={isOffset ? 'dark' : 'white'} />
          </RouterLink>
          <MHidden width="mdDown">
            <Button
              target="_blank"
              sx={{ textTransform: 'none', ml: '2.5rem' }}
              href={process.env.REACT_APP_CANDIDATE_URL}
              {...(!isOffset && { color: 'inherit' })}
            >
              <Typography>Para Candidatos</Typography>
            </Button>
          </MHidden>
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <Typography sx={{ mr: '1.5rem' }} color={isOffset ? 'primary' : 'inherit'}>
              Já possui uma conta?
            </Typography>
            <Button
              sx={{ textTransform: 'none', mr: '1.5rem', width: '10rem' }}
              href="/auth/login"
              variant="outlined"
              {...(!isOffset && { color: 'clear' })}
            >
              Entrar
            </Button>
          </MHidden>

          <MHidden width="smDown">
            <Button
              sx={{
                width: '10rem',
                color: 'white',
                whiteSpace: 'nowrap',
                backgroundColor: 'primary.main',
                p: '0.438rem 0.844rem',
                borderRadius: '0.5rem',
                textTransform: 'none'
              }}
              onClick={handleScrollDown}
              // href="/auth/register"
              variant="contained"
              {...(!isOffset && { color: 'clear' })}
            >
              Cadastre-se
            </Button>
          </MHidden>
          <MHidden width="smUp">
            <Button
              sx={{
                color: 'white',
                whiteSpace: 'nowrap',
                backgroundColor: 'primary.main',
                p: '0.438rem 0.844rem',
                borderRadius: '0.5rem',
                textTransform: 'none'
              }}
              onClick={handleScrollUp}
              // href="/auth/register"
              variant="contained"
              {...(!isOffset && { color: 'clear' })}
            >
              Cadastre-se
            </Button>
          </MHidden>

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
