import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default function Logo({ sx, color, width = 116, height = 24 }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;

  function getColor(color) {
    if (color === 'white') return '#FFF';
    if (color === 'dark') return PRIMARY_MAIN;
    return PRIMARY_LIGHT;
  }

  return (
    <Box sx={{ ...sx, display: 'flex', justifyContent: 'center' }}>
      <svg width={width} height={height} viewBox="0 0 116 24" fill={getColor(color)} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.0959 13.0711C21.0959 13.9807 20.9734 14.8402 20.7284 15.6498C20.4925 16.4503 20.1522 17.1779 19.7076 17.8329C19.2721 18.4878 18.7413 19.0608 18.1152 19.552C17.4982 20.0432 16.8086 20.4298 16.0465 20.7118L20.184 24H15.0529L11.6504 21.2575H8.16614C6.99566 21.2575 5.91138 21.0574 4.91329 20.6572C3.91521 20.2479 3.05323 19.6794 2.32735 18.9517C1.60147 18.224 1.02984 17.3599 0.61246 16.3593C0.204153 15.3587 0 14.2626 0 13.0711V9.33257C0 8.15008 0.204153 7.05856 0.61246 6.05799C1.02984 5.04832 1.60147 4.1842 2.32735 3.46561C3.05323 2.73792 3.91521 2.16941 4.91329 1.76009C5.91138 1.35077 6.99566 1.14611 8.16614 1.14611H12.9569C14.1274 1.14611 15.2072 1.35077 16.1962 1.76009C17.1943 2.16941 18.0562 2.73792 18.7821 3.46561C19.508 4.1842 20.0751 5.04832 20.4834 6.05799C20.8917 7.05856 21.0959 8.15008 21.0959 9.33257V13.0711ZM17.5572 9.33257C17.5572 8.63218 17.4438 8 17.2169 7.43604C16.9992 6.86299 16.6861 6.37635 16.2778 5.97612C15.8786 5.5668 15.3932 5.25298 14.8215 5.03468C14.259 4.80728 13.6375 4.69358 12.9569 4.69358H8.16614C7.47655 4.69358 6.84595 4.80728 6.27432 5.03468C5.71176 5.25298 5.22633 5.5668 4.81802 5.97612C4.40971 6.37635 4.09214 6.86299 3.86531 7.43604C3.64754 8 3.53866 8.63218 3.53866 9.33257V13.0711C3.53866 13.7715 3.64754 14.4082 3.86531 14.9812C4.09214 15.5452 4.40971 16.0318 4.81802 16.4412C5.22633 16.8414 5.71176 17.1552 6.27432 17.3826C6.84595 17.6009 7.47655 17.7101 8.16614 17.7101H12.9297C13.6193 17.7101 14.2454 17.6009 14.8079 17.3826C15.3796 17.1552 15.8695 16.8414 16.2778 16.4412C16.6861 16.0318 16.9992 15.5452 17.2169 14.9812C17.4438 14.4082 17.5572 13.7715 17.5572 13.0711V9.33257Z"
          fill={getColor(color)}
        />
        <path
          d="M41.7562 6.23536C41.357 7.48152 40.8897 8.70949 40.3543 9.91927C39.819 11.1291 39.202 12.2843 38.5033 13.3849C37.8138 14.4764 37.038 15.4906 36.176 16.4275C35.3231 17.3553 34.3749 18.1603 33.3315 18.8425C32.2971 19.5156 31.1674 20.0478 29.9425 20.4389C28.7267 20.8209 27.4065 21.0119 25.9819 21.0119C25.7369 21.0119 25.5056 20.9665 25.2878 20.8755C25.07 20.7845 24.8795 20.6617 24.7162 20.5071C24.5529 20.3434 24.4258 20.1569 24.3351 19.9477C24.2444 19.7294 24.199 19.4974 24.199 19.2518V6.23536H27.7377V17.328C28.5996 17.328 29.4253 17.1416 30.2147 16.7686C31.0132 16.3957 31.7663 15.9045 32.474 15.2951C33.1817 14.6765 33.835 13.9761 34.4339 13.1939C35.0418 12.4116 35.5817 11.6111 36.0535 10.7925C36.5253 9.96475 36.9246 9.1552 37.2512 8.36384C37.5869 7.57248 37.8364 6.86299 37.9998 6.23536H41.7562Z"
          fill={getColor(color)}
        />
        <path
          d="M58.8643 11.12C58.8643 11.6111 58.7781 12.1433 58.6057 12.7163C58.4333 13.2803 58.152 13.8078 57.7618 14.299C57.3807 14.7811 56.8772 15.1859 56.2511 15.5134C55.6341 15.8408 54.881 16.0045 53.9918 16.0045H47.6086V12.6345H53.9918C54.4727 12.6345 54.8447 12.4889 55.1078 12.1978C55.371 11.8977 55.5025 11.5293 55.5025 11.0927C55.5025 10.6288 55.3528 10.2649 55.0534 10.0011C54.763 9.73735 54.4092 9.60546 53.9918 9.60546H47.6086C47.1277 9.60546 46.7557 9.75554 46.4925 10.0557C46.2294 10.3468 46.0979 10.7106 46.0979 11.1472V16.1001C46.0979 16.5731 46.243 16.9414 46.5334 17.2052C46.8328 17.469 47.2003 17.6009 47.6358 17.6009H53.9918V20.9846H47.6086C47.1186 20.9846 46.5878 20.8982 46.0162 20.7254C45.4536 20.5526 44.9274 20.2752 44.4374 19.8931C43.9565 19.502 43.5527 18.9972 43.2261 18.3786C42.8995 17.751 42.7361 16.9915 42.7361 16.1001V11.12C42.7361 10.6288 42.8223 10.1012 42.9947 9.53724C43.1671 8.96418 43.4439 8.43661 43.8249 7.95452C44.2151 7.46333 44.7187 7.05401 45.3357 6.72655C45.9618 6.39909 46.7194 6.23536 47.6086 6.23536H53.9918C54.4818 6.23536 55.008 6.32177 55.5706 6.4946C56.1422 6.66742 56.6685 6.9494 57.1494 7.34053C57.6393 7.72257 58.0476 8.2274 58.3743 8.85503C58.7009 9.47356 58.8643 10.2285 58.8643 11.12Z"
          fill={getColor(color)}
        />
        <path
          d="M77.5511 20.9846H74.0124V12.7982C74.0124 12.3343 73.9308 11.9204 73.7675 11.5566C73.6132 11.1836 73.4 10.8653 73.1278 10.6015C72.8556 10.3377 72.5335 10.1376 72.1614 10.0011C71.7985 9.8556 71.4038 9.78283 70.9774 9.78283H64.7847V20.9846H61.246V7.99545C61.246 7.74986 61.2914 7.52246 61.3821 7.31325C61.4729 7.09494 61.5999 6.90847 61.7632 6.75384C61.9266 6.59011 62.1171 6.46276 62.3349 6.3718C62.5526 6.28084 62.784 6.23536 63.029 6.23536H71.0046C71.4492 6.23536 71.9165 6.28539 72.4064 6.38545C72.9055 6.4855 73.3954 6.64923 73.8763 6.87663C74.3663 7.09494 74.8291 7.37692 75.2646 7.72257C75.7092 8.05912 76.0993 8.473 76.4351 8.96418C76.7799 9.44628 77.0521 10.0057 77.2517 10.6424C77.4513 11.2791 77.5511 11.9977 77.5511 12.7982V20.9846Z"
          fill={getColor(color)}
        />
        <path
          d="M96.796 16.0045C96.796 16.614 96.7188 17.1643 96.5646 17.6555C96.4103 18.1376 96.2062 18.5651 95.9521 18.938C95.6981 19.3019 95.4032 19.6157 95.0675 19.8795C94.7318 20.1342 94.3779 20.3434 94.0059 20.5071C93.6429 20.6708 93.2709 20.7936 92.8898 20.8755C92.5178 20.9483 92.1639 20.9846 91.8282 20.9846H85.445C84.8371 20.9846 84.2927 20.9073 83.8118 20.7527C83.3309 20.5981 82.9045 20.3934 82.5324 20.1387C82.1695 19.8749 81.8565 19.5748 81.5933 19.2382C81.3393 18.9016 81.1306 18.5515 80.9673 18.1876C80.8039 17.8147 80.6815 17.4417 80.5998 17.0688C80.5272 16.6868 80.4909 16.332 80.4909 16.0045V11.2018C80.4909 10.2922 80.6542 9.52359 80.9809 8.89596C81.3166 8.25924 81.7294 7.74531 82.2194 7.35418C82.7184 6.95395 83.2583 6.66742 83.839 6.4946C84.4197 6.32177 84.9551 6.23536 85.445 6.23536H91.8282V9.78283H85.4723C85.0004 9.78283 84.642 9.90563 84.397 10.1512C84.1521 10.3877 84.0296 10.7379 84.0296 11.2018V15.9773C84.0296 16.4594 84.1475 16.8232 84.3834 17.0688C84.6284 17.3144 84.9823 17.4372 85.445 17.4372H91.8282C92.3091 17.4372 92.6675 17.3144 92.9034 17.0688C93.1394 16.8232 93.2573 16.4684 93.2573 16.0045V0H96.796V16.0045Z"
          fill={getColor(color)}
        />
        <path
          d="M116 11.12C116 11.6111 115.914 12.1433 115.741 12.7163C115.569 13.2803 115.288 13.8078 114.898 14.299C114.516 14.7811 114.013 15.1859 113.387 15.5134C112.77 15.8408 112.017 16.0045 111.128 16.0045H104.744V12.6345H111.128C111.608 12.6345 111.98 12.4889 112.244 12.1978C112.507 11.8977 112.638 11.5293 112.638 11.0927C112.638 10.6288 112.489 10.2649 112.189 10.0011C111.899 9.73735 111.545 9.60546 111.128 9.60546H104.744C104.263 9.60546 103.891 9.75554 103.628 10.0557C103.365 10.3468 103.234 10.7106 103.234 11.1472V16.1001C103.234 16.5731 103.379 16.9414 103.669 17.2052C103.969 17.469 104.336 17.6009 104.772 17.6009H111.128V20.9846H104.744C104.254 20.9846 103.724 20.8982 103.152 20.7254C102.589 20.5526 102.063 20.2752 101.573 19.8931C101.092 19.502 100.688 18.9972 100.362 18.3786C100.035 17.751 99.8719 16.9915 99.8719 16.1001V11.12C99.8719 10.6288 99.9581 10.1012 100.13 9.53724C100.303 8.96418 100.58 8.43661 100.961 7.95452C101.351 7.46333 101.854 7.05401 102.471 6.72655C103.098 6.39909 103.855 6.23536 104.744 6.23536H111.128C111.618 6.23536 112.144 6.32177 112.706 6.4946C113.278 6.66742 113.804 6.9494 114.285 7.34053C114.775 7.72257 115.183 8.2274 115.51 8.85503C115.837 9.47356 116 10.2285 116 11.12Z"
          fill={getColor(color)}
        />
      </svg>
    </Box>
  );
}
